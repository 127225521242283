import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { AnimatedComponent } from './components';
import MainRoutes from './routes';
import LoadingScreen from './pages/loading-screen';
import { StepProvider } from './context/step-context';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
import './assets/css/style.css';
import './App.css';

function App() {
	const [loading, setLoading] = useState<boolean>(true);
	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
		return () => {};
	}, []);

	return (
		<StepProvider>
			<ToastContainer />
			<MainRoutes />
			<AnimatedComponent className="loading_screen" style={{ zIndex: 2 }} condition={loading} initial={{ opacity: 1, scale: 1 }} duration={2}>
				<LoadingScreen transparent={false} />
			</AnimatedComponent>
		</StepProvider>
	);
}

export default App;
