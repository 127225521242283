import React from 'react';
import { Col, Row } from 'antd';
import { LottieComponent, VButton } from 'components';
import LottieFile from 'assets/lottie-files';
import './style.scss';

interface SuccessPageProps {
	handleCloseModal: () => void;
}

const SuccessPage = ({ handleCloseModal }: SuccessPageProps) => {
	return (
		<Row justify="center" align="middle" className="success_page">
			<Col className="container">
				<Row justify="center">
					<Col>
						<div className="title">Referral Created Successfully</div>
					</Col>
					<Col xs={24} md={12} xl={24}>
						<VButton onClick={handleCloseModal} style={{ width: '100%' }}>
							Close
						</VButton>
					</Col>
				</Row>
				<div className="lottie">
					<LottieComponent file={LottieFile.Confetti} width={'100%'} height={'100%'} />
				</div>
			</Col>
		</Row>
	);
};

export default SuccessPage;
