import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLayout } from 'components';
import ProtectedRoute from './protected-route';
import GetReferralCode from 'pages/referral-code/get-referral-code';
import VerifyCode from 'pages/referral-code/verify-code';
import Step1 from 'pages/steps/step1';
import Step2 from 'pages/steps/step2';
import Step3 from 'pages/steps/step3';
import NoAccess from 'pages/no-access';

const MainRoutes = () => {
	return (
		<Routes>
			<Route path="/verify-code" element={<VerifyCode />} />
			<Route element={<PageLayout />}>
				<Route
					path="/step1"
					element={
						<ProtectedRoute>
							<Step1 />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/step2"
					element={
						<ProtectedRoute>
							<Step2 />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/step3"
					element={
						<ProtectedRoute>
							<Step3 />
						</ProtectedRoute>
					}
				/>
			</Route>
			<Route path="/" element={<GetReferralCode />} />
			<Route path="*" element={<NoAccess />} />
		</Routes>
	);
};

export default MainRoutes;
