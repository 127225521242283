import React from 'react';
import { useNavigate } from 'react-router-dom';
import { values } from 'lodash';
import { useStepContext } from 'context/step-context';
import Step2Presentational from './step2-presentational';

const Step2Functional = () => {
	const navigate = useNavigate();
	const inputFile = React.useRef(null);
	const {
		stepDetails: { step2 },
		handleStepInputChange,
	} = useStepContext();

	const handleInputChange = (label, value) => {
		handleStepInputChange('step2', label, value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate('/step3');
	};

	const handleFiles = (e) => {
		const target = e.target;
		const files = values(target.files).map((x) => {
			return {
				name: x.name,
				fileName: x.name,
				file: x,
				fileLabel: x.name,
				type: x.type || '',
			};
		});
		handleInputChange('files', [...step2?.files, ...files]);
	};
	const removeFile = (name) => {
		const files = step2?.files.filter((x) => x.name !== name);
		handleInputChange('files', files);
	};

	return <Step2Presentational {...{ navigate, step2, handleInputChange, handleSubmit, handleFiles, inputFile, removeFile }} />;
};

export default Step2Functional;
