import * as FA from 'react-icons/fa';
// import * as IO from 'react-icons/io5';

export const APP_VARIABLES = {
	ACCESS_TOKEN: 'ACCESS_TOKEN',
	ID_TOKEN: 'id_token',
	AUTH_CODE: 'AUTH_CODE',
	REDIRECT_TO: 'REDIRECT_TO',
	USER_DETAILS: 'USER_DETAILS',
};

export const ICON_TYPES = {
	FA: FA,
};

export const MENUS = [];

export const ANIMATION_CONFIGS = {
	ENTER: {
		ENTER_BY_FADE: {},
	},
	EXIT: {
		EXIT_BY_SCALE: { opacity: 0, scale: 10 },
		EXIT_BY_FADE: { opacity: 0 },
	},
};
