import axios from 'axios';
import { message } from 'antd';
import { APP_VARIABLES } from 'constants/app-constants';
import { API_URL } from 'globals/api-url';
import { SAVE_USER, SAVE_REFERRAL, SAVE_FILE } from './step.types';
import ERRORS from 'globals/errorConstants';

export const saveUser =
	(data = {}) =>
	async (dispatch: any) => {
		await dispatch({
			type: SAVE_USER.REQUEST,
		});
		try {
			const { data: details } = await axios.post(API_URL.SAVE_USER, data);
			await dispatch({
				type: SAVE_USER.SUCCESS,
				details,
			});
		} catch (error) {
			await dispatch({
				type: SAVE_USER.ERROR,
			});
		}
	};

export const saveReferral =
	(data = {}) =>
	async (dispatch: any) => {
		await dispatch({
			type: SAVE_REFERRAL.REQUEST,
		});
		try {
			const {
				data: { response: details, response_code },
			} = await axios.post(API_URL.SAVE_REFERRAL, data);

			if (response_code === '0') {
				await dispatch({
					type: SAVE_REFERRAL.SUCCESS,
					details,
				});
			} else {
				message.error(details?.response_message || ERRORS.CLIENT_ERROR.message);
				await dispatch({
					type: SAVE_REFERRAL.ERROR,
				});
			}
		} catch (error) {
			await dispatch({
				type: SAVE_REFERRAL.ERROR,
			});
		}
	};

export const saveFile =
	(data = {}) =>
	async (dispatch: any) => {
		await dispatch({
			type: SAVE_FILE.REQUEST,
		});
		try {
			const { data: details } = await axios.post(API_URL.SAVE_FILE, data);
			await dispatch({
				type: SAVE_FILE.SUCCESS,
				details,
			});
			await localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
		} catch (error) {
			await dispatch({
				type: SAVE_FILE.ERROR,
			});
		}
	};
