import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_VARIABLES } from 'constants/app-constants';

const ProtectedRoute = ({ redirectPath = '/', children }) => {
	const codeVerified = useSelector(({ referral: { codeVerified } }) => codeVerified);

	const authCode = localStorage.getItem(APP_VARIABLES.AUTH_CODE);

	if (!codeVerified) {
		return <Navigate to={`${redirectPath}?authCode=${authCode}`} replace />;
	}

	return children;
};

export default ProtectedRoute;
