import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { APP_VARIABLES } from 'constants/app-constants';
import { API_STATUS } from 'globals/api-constants';
import { checkStatus } from 'helpers';
import { CommonProps, ReferralProps } from 'modal/common';
import { verifyReferralCode, getReferralCode } from 'pages/referral-code/actions-reducers/referral-code.actions';
import { VERIFY_REFERRAL_CODE } from 'pages/referral-code/actions-reducers/referral-code.types';
import { useStepContext } from 'context/step-context';
import { getPhysicianDetails, getRoles, getSpecialities } from 'store/common/common.actions';
import VerifyCodePresentational from './verify-code-presentational';

interface LocationProps {
	id: string;
}

const VerifyCodeFunctional = () => {
	const [verificationCode, setVerificationCode] = React.useState('');
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { handleStep1Values } = useStepContext();
	const referralRedux = useSelector(({ referral }: ReferralProps) => referral);
	const codeVerified = useSelector(({ referral: { codeVerified } }: ReferralProps) => codeVerified);
	const tokenExpiredStatus = useSelector(({ referral: { codeVerification } }: any) => codeVerification);
	const [verifyCode, setVerifyCode] = useState(false);
	const physicianDetailsFetched = useSelector(
		({
			common: {
				physicianDetails: { apiStatus },
			},
		}: CommonProps) => apiStatus === API_STATUS.SUCCESS
	);
	const { id } = location.state as LocationProps;

	const isLoading = React.useMemo(() => {
		return referralRedux.apiStatus === API_STATUS.PENDING;
	}, [referralRedux]);

	React.useEffect(() => {
		if (tokenExpiredStatus && tokenExpiredStatus.hasOwnProperty('tokenExpired') && tokenExpiredStatus.tokenExpired) {
			setVerifyCode(true);
		}
	}, [tokenExpiredStatus]);

	React.useEffect(() => {
		if (checkStatus(referralRedux.apiStatus)) {
			let values = {
				firstName: referralRedux?.referralData?.userVerificationDetail?.firstName || '',
				lastName: referralRedux?.referralData?.userVerificationDetail?.lastName || '',
				primaryEmail: referralRedux?.referralData?.userVerificationDetail?.email || '',
				primaryMobile: referralRedux?.referralData?.userVerificationDetail?.mobile || '',
				userType: referralRedux?.referralData?.userVerificationDetail?.userType ?? 0,
				practiceName:
					referralRedux?.referralData?.userVerificationDetail?.facilityName === null &&
					Number(referralRedux?.referralData?.userVerificationDetail?.userType) !== 0
						? referralRedux?.referralData?.userVerificationDetail?.practiceName
						: referralRedux?.referralData?.userVerificationDetail?.facilityName || '',
				department: referralRedux?.referralData?.userVerificationDetail?.department || '',
				practicePhone: referralRedux?.referralData?.userVerificationDetail?.practicePhoneNumber || '',
				specialityUserId: referralRedux?.referralData?.userVerificationDetail?.specialityId || '',
				npiNumber: referralRedux?.referralData?.userVerificationDetail?.npiNumber || '',
			};
			handleStep1Values(values);
			const token = referralRedux?.referralData?.response?.access_token || '';
			localStorage.setItem(APP_VARIABLES.ACCESS_TOKEN, token);
			dispatch({
				type: VERIFY_REFERRAL_CODE.RESET,
			});
			const authCode = localStorage.getItem(APP_VARIABLES.AUTH_CODE);
			if (authCode) {
				dispatch(getPhysicianDetails(authCode));
				dispatch(getRoles());
				dispatch(getSpecialities());
			}
		}
	}, [referralRedux, navigate, dispatch, handleStep1Values]);

	const handleVerifyCode = () => {
		setVerifyCode(false);
		dispatch(
			verifyReferralCode({
				id,
				verificationCode,
			})
		);
	};
	const verificationResetCode = () => {
		setVerifyCode(false);
		dispatch(getReferralCode(JSON.parse(sessionStorage.details)));
	};
	if (codeVerified && physicianDetailsFetched) {
		return <Navigate to={'/step1'} replace />;
	}

	const disabled = !(verificationCode?.length === 6);

	return (
		<VerifyCodePresentational
			{...{ verifyCode, handleVerifyCode, verificationCode, setVerificationCode, isLoading, disabled, verificationResetCode }}
		/>
	);
};

export default VerifyCodeFunctional;
