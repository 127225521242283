import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ANIMATION_CONFIGS } from 'constants/app-constants';

const AnimatedComponent = ({
	condition = true,
	children = {},
	exitAnimation = ANIMATION_CONFIGS.EXIT.EXIT_BY_FADE,
	className = '',
	style = {},
	initial = { opacity: 0, scale: 1 },
	animate = { opacity: 1, scale: 1 },
	duration = 0.5,
	delay = 0,
}) => {
	return (
		<AnimatePresence>
			{condition && (
				<motion.div
					{...{
						transition: { duration, delay },
						className,
						style,
						initial,
						animate,
						exit: exitAnimation || ANIMATION_CONFIGS.EXIT.EXIT_BY_FADE,
					}}>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default AnimatedComponent;
