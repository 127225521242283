import axios from 'axios';
import { API_URL } from 'globals/api-url';
import { INCREMENT, DECREMENT, GET_SPECIALITIES, GET_ROLES, GET_PHYSICIAN_DETAILS } from './common.types';

export const increaseCounter = (dispatch: any) => {
	dispatch({
		type: INCREMENT,
	});
};
export const decreaseCounter = (dispatch: any) => {
	dispatch({
		type: DECREMENT,
	});
};

export const getSpecialities = () => async (dispatch: any) => {
	await dispatch({
		type: GET_SPECIALITIES.REQUEST,
	});
	try {
		const {
			data: { response: details },
		} = await axios.get(API_URL.GET_SPECIALITIES);
		await dispatch({
			type: GET_SPECIALITIES.SUCCESS,
			details,
		});
	} catch (error) {
		await dispatch({
			type: GET_SPECIALITIES.ERROR,
		});
	}
};

export const getRoles = () => async (dispatch: any) => {
	await dispatch({
		type: GET_ROLES.REQUEST,
	});
	try {
		const {
			data: { response: details },
		} = await axios.get(API_URL.GET_ROLES);
		await dispatch({
			type: GET_ROLES.SUCCESS,
			details,
		});
	} catch (error) {
		await dispatch({
			type: GET_ROLES.ERROR,
		});
	}
};

export const getPhysicianDetails =
	(authCode = '') =>
	async (dispatch: any) => {
		await dispatch({
			type: GET_PHYSICIAN_DETAILS.REQUEST,
		});
		try {
			const {
				data: { response: details },
			} = await axios.get(`${API_URL.GET_PHYSICIAN_DETAILS}?authCode=${authCode}`);
			await dispatch({
				type: GET_PHYSICIAN_DETAILS.SUCCESS,
				details,
			});
		} catch (error) {
			await dispatch({
				type: GET_PHYSICIAN_DETAILS.ERROR,
			});
		}
	};
