import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { getReferralCode } from 'pages/referral-code/actions-reducers/referral-code.actions';
import { GET_REFERRAL_CODE } from 'pages/referral-code/actions-reducers/referral-code.types';
import { checkStatus, checkTheObjectAllFilledWithValues, validateEmail } from 'helpers';
import { API_STATUS } from 'globals/api-constants';
import { notification } from 'utils/notification';
import { APP_VARIABLES } from 'constants/app-constants';
import { ReferralProps } from 'modal/common';
import { useStepContext } from 'context/step-context';
import NoAccess from 'pages/no-access';
import { getPhysicianDetails } from 'store/common/common.actions';
import GetReferralCodePresentational from './get-referral-code-presentational';

const GetReferralCodeFunctional = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [details, setDetails] = React.useState({
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
	});
	let [searchParams] = useSearchParams();
	const authCode = searchParams.get('authCode') || '';
	const { handleStep1Values } = useStepContext();
	const {
		roles: { data },
	} = useSelector(({ common }: { common: any }) => common);

	useEffect(() => {
		if (authCode) {
			localStorage.setItem(APP_VARIABLES.AUTH_CODE, authCode);
			dispatch(getPhysicianDetails(authCode));
		}
	}, [authCode, dispatch]);
	const referralRedux = useSelector(({ referral }: ReferralProps) => referral);
	const codeVerified = useSelector(({ referral: { codeVerified } }: ReferralProps) => codeVerified);
	const physicianDetails = useSelector(({ common }: { common: any }) => common?.physicianDetails)?.data;
	const loading = React.useMemo(() => {
		return referralRedux?.apiStatus === API_STATUS.PENDING || false;
	}, [referralRedux]);

	React.useEffect(() => {
		sessionStorage.clear();
	}, []);

	React.useEffect(() => {
		if (checkStatus(referralRedux.apiStatus)) {
			const token = referralRedux?.referralData?.response?.access_token || '';
			localStorage.setItem(APP_VARIABLES.ACCESS_TOKEN, token);
			let values = {
				firstName: referralRedux?.referralData?.userVerificationDetail?.firstName,
				lastName: referralRedux?.referralData?.userVerificationDetail?.lastName,
				primaryEmail: referralRedux?.referralData?.userVerificationDetail?.email,
				primaryMobile: referralRedux?.referralData?.userVerificationDetail?.mobile,
				userType: referralRedux?.referralData?.userVerificationDetail?.userType,
				specialityUserId: referralRedux?.referralData?.userVerificationDetail?.specialityId || '',
			};
			notification({
				message: 'We have sent you a referral code. Please check your inbox.',
			});
			handleStep1Values(values);
			navigate('/verify-code', {
				state: {
					...(referralRedux.referralData || {}),
				},
			});
			dispatch({
				type: GET_REFERRAL_CODE.RESET,
			});
		}
	}, [referralRedux, navigate, dispatch, handleStep1Values]);

	const handleInputChange = (label = '', value = '') => {
		setDetails({ ...details, [label]: value });
	};

	const handleSubmit = () => {
		// const roleInText = data.find((role: any) => role.utypeId === parseInt(details.role))?.userType;
		const request = {
			...details,
			type: 'QUICKREFERRAL',
		};
		localStorage.removeItem('mytime');
		localStorage.removeItem('ACCESS_TOKEN');
		sessionStorage.setItem('details', JSON.stringify(request));
		dispatch(getReferralCode(request));
	};
	const disabled = !checkTheObjectAllFilledWithValues(details) || !validateEmail(details.email);
	if (!authCode) {
		return <NoAccess />;
	}
	if (codeVerified) {
		return <Navigate to={'/step1'} replace />;
	}

	return <GetReferralCodePresentational {...{ handleSubmit, handleInputChange, details, loading, disabled, roles: data, physicianDetails }} />;
};

export default GetReferralCodeFunctional;
