import React from 'react';
import { Images } from 'assets/images';
import './header.scss';

const VHeader = () => {
	return (
		<div className="container-fluid my-3">
			<div className="row">
				<div className="col-sm-3 align-self-center my-3 my-sm-0 text-center text-sm-left">
					<img className="logo" src={Images.vLogo} alt="#"></img>
				</div>
				<div className="col-sm-6 align-self-center my-3 my-sm-0 text-center">
					<h3>VitalEngine Quick Refer</h3>
				</div>
				<div className="col-sm-3 align-self-center my-3 my-sm-0 text-center text-sm-right">
					<img className="w-50" src={Images.qr} alt="#" />
				</div>
			</div>
		</div>
	);
};

export default VHeader;
