import React from 'react';
import { Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import LoadingScreen from 'pages/loading-screen';
import Stepper from 'pages/steps/components/stepper';
import SuccessPage from 'pages/success-page';
import { PhysicianDetailsProps, Step1ContextProps, Step2ContextProps } from 'modal/common';
import { getIcons } from 'helpers';

interface Step3Props {
	navigate: (a: Number) => void;
	handleCloseModal: () => void;
	handleSubmit: () => void;
	pageLoading: boolean;
	step1: Step1ContextProps;
	step2: Step2ContextProps;
	userRoleInText: string;
	successModal: boolean;
	physicianDetails: PhysicianDetailsProps;
}
const Step3Presentational = ({
	navigate,
	step1,
	step2,
	handleSubmit,
	userRoleInText,
	physicianDetails,
	successModal,
	handleCloseModal,
	pageLoading,
}: Step3Props) => {
	const isPhysician = (step1?.userType ?? '').toString() === '1';
	return (
		<>
			<div className="container mb-3 step3 pb-3">
				<Stepper />
				<div className="row mt-3">
					<div className="col-12">
						<div className="border box_shadow1 no_border border_radius">
							<div className="px-3  py-2 text-white bg-primary border_radius">
								<p className="mb-0">Referral Preview</p>
							</div>
							{/* <div className="p-3"><p className="text-danger mb-0">VitalEngine Referral #74335</p></div> */}
							<div className="d-sm-flex pb-3 px-3" style={{ paddingTop: 10 }}>
								<div className="align-self-center">
									<h5>{isPhysician ? `${step1?.firstName} ${step1?.lastName}` : `${step1?.referringProviderName}`}</h5>
									{isPhysician ? <p>Phone {step1?.primaryMobile}</p> : <p></p>}
									<p>
										Contact: {`${step1?.firstName} ${step1?.lastName}`} <br /> {userRoleInText}
									</p>
								</div>
								<div className="d-flex text-info m-3 text-nowrap align-self-center">
									<h5>IS SENDING</h5>
								</div>
								<div className="align-self-center">
									<h5>
										{/* Mr.  */}
										{step2?.firstName} {step2?.middleName} {step2?.lastName}
									</h5>
									<div>
										{step2?.address} {step2?.city} {step2?.state} {step2?.zip}
									</div>
									<p>Phone {step2?.phone}</p>
									<p>
										{step2 && step2?.contactName !== '' && (
											<div>
												<span>Contact Relation Name: {step2?.contactName}</span>
											</div>
										)}
										{step2 && step2?.contactPhone !== '' && (
											<div>
												<span>Contact Relation Phone: {step2?.contactPhone}</span>
											</div>
										)}
										{step2 && step2?.contactRelationship !== '' && (
											<div>
												<span>Contact Relationship: {step2?.contactRelationship}</span>
											</div>
										)}
									</p>
								</div>
								<div className="d-flex text-info m-3 text-nowrap align-self-center">
									<h5>to</h5>
								</div>
								<div className="align-self-center">
									<h5>
										{physicianDetails?.title}. {physicianDetails?.firstName} {physicianDetails?.lastName}
									</h5>
									<div>
										{physicianDetails?.physicalAddress1} {physicianDetails?.physicalPostalCode}
									</div>
									<br />
									<p>
										Contact: <br /> {physicianDetails?.primaryMobile}
									</p>
								</div>
								<div className="d-flex text-info m-3 text-nowrap align-self-center">
									<h5>For</h5>
								</div>
								<div className="align-self-center">
									<p style={{ wordBreak: 'break-word' }}>{step2?.reason || ''}</p>
								</div>
							</div>
							<div className="p-3 d-sm-flex justify-content-between">
								<div className="col-md-4 col-xl-4">
									<p className="fw-bold">Attachments</p>
									<div className="row">
										{step2?.files?.map(({ name, type = '' }, index) => (
											<div key={index} className="col-md-2 d-flex flex-column me-3 my-2">
												<FontAwesomeIcon className="h1" icon={getIcons(type)} />
												<small className="text-ellipsis">{name}</small>
											</div>
										))}
									</div>
								</div>
								<div className="ms-sm-3 mt-3 mt-sm-0">
									<div className="border p-3">
										<p className="mb-0">
											When you submit, you will receive an email confirmation and be given the opportunity to login and manage
											the referral.
										</p>
									</div>
									<div className="d-flex mt-3 justify-content-end">
										<div className="d-flex me-3" onClick={() => navigate(-1)}>
											<p className="mb-0 align-self-center me-2">Back</p>
											<button title="Back" className="btn btn-outline-dark btn-sm">
												<FontAwesomeIcon icon={solid('chevron-left')} />
											</button>
										</div>
										<div className="d-flex">
											<button
												title="Submit"
												onClick={handleSubmit}
												className="btn bg-primary btn-sm"
												style={{ color: 'white' }}>
												<p className="mb-0 align-self-center">Submit</p>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{pageLoading && <LoadingScreen transparent />}
			<Drawer
				// title="Referral Created Successfully"
				placement={'bottom'}
				height="90%"
				onClose={handleCloseModal}
				visible={successModal}>
				<SuccessPage {...{ handleCloseModal }} />
			</Drawer>
		</>
	);
};

export default Step3Presentational;
