import React from 'react';
import { Images } from 'assets/images';
import { VButton } from 'components';
import { extractOnlyCharactersWithSpace, validateEmail } from 'helpers';
import { API_BASE_URL } from 'globals/api-url';
import PhoneNumberInput from 'components/PhoneNumberInput';

interface GetReferralCodeProps {
	loading: boolean | string;
	disabled: boolean;
	details: {
		firstName: string;
		lastName: string;
		email: string;
		mobile: string;
		// role: string;
	};
	physicianDetails: any;
	handleSubmit: () => void;
	handleInputChange: (key: string, value: string) => void;
	roles: Array<{
		userType: string;
		utypeId: string;
	}>;
}

const GetReferralCodePresentational = ({
	handleSubmit,
	handleInputChange,
	details,
	physicianDetails,
	loading = false,
	disabled = false,
}: GetReferralCodeProps) => {
	const emailValidation = !validateEmail(details?.email);
	return (
		<div className="container vertical-align">
			<div className="row align-items-center">
				<div className="col-sm-12 align-self-center my-sm-0 text-center mb-3">
					<img className="logo my-3" src={Images.vLogo} alt="LOGO" />
				</div>
				<div className="col-sm-12 align-self-center mb-3 text-center">
					<h5>
						Welcome to {physicianDetails.title}&nbsp;{physicianDetails.displayName}'s referral page
					</h5>
				</div>
				<div className="col-sm-12 align-self-center my-sm-0 text-center mb-3">
					<img
						className="logopic box_shadow2 border_radius mb-3"
						src={
							physicianDetails?.photo
								? `${API_BASE_URL}/portal-api/${physicianDetails?.photo}`
								: 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
						}
						alt="img"
					/>
				</div>
				<div className="col-sm-12 align-self-center mb-3 text-center">
					<h5>
						Facility Name: {physicianDetails.practiceName}
					</h5>
				</div>
				<div className="col-sm-12 col-xl-6">
					<div className="row">
						<div className="col-sm-6 mb-3">
							<label>
								<b>Your First name*</b>
							</label>
							<input
								required
								type="text"
								className="form-control"
								title="Your Name"
								value={details?.firstName}
								maxLength={20}
								onChange={({ target: { value } }) => handleInputChange('firstName', extractOnlyCharactersWithSpace(value))}
							/>
						</div>
						<div className="col-sm-6 mb-3">
							<label>
								<b>Your Last name*</b>
							</label>
							{/* <select
								title="Please select a speciality"
								className="form-control"
								name="speciality"
								id="speciality"
								onChange={({ target: { value } }) => handleInputChange('role', value)}>
								<option value="">Select Speciality</option>
								{roles?.map((role, index) => (
									<option key={index} value={role?.utypeId}>
										{role?.userType}
									</option>
								))}
							</select> */}
							<input
								required
								type="text"
								className="form-control"
								title="Your Name"
								value={details?.lastName}
								maxLength={20}
								onChange={({ target: { value } }) => handleInputChange('lastName', extractOnlyCharactersWithSpace(value))}
							/>
						</div>
						<div className="col-sm-6 mb-3">
							<label>
								<b
									{...{
										...(emailValidation && {
											className: 'wrong',
										}),
									}}>
									Your Email* {`${emailValidation ? '- Email is Incorrect' : ''}`}
								</b>
							</label>
							<input
								required
								type="text"
								title="Your Email"
								className="form-control"
								value={details?.email}
								onChange={({ target: { value } }) => handleInputChange('email', value)}
							/>
						</div>
						<div className="col-sm-6 mb-3">
							<label>
								<b>Your Cell Phone*</b>
							</label>
							<PhoneNumberInput
								onChange={(val) => handleInputChange('mobile', val)}
								placeholder="Your cell phone"
								defaultValue={details.mobile}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row my-4 justify-content-center">
				<div className="col-sm-5 col-lg-5 col-xl-5">
					<VButton loading={loading} disabled={disabled} onClick={handleSubmit}>
						Get Referral Start Code
					</VButton>
				</div>
			</div>
		</div>
	);
};

export default GetReferralCodePresentational;
