import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStepContext } from 'context/step-context';
import { checkStatus, formReferralParticipants } from 'helpers';
import { saveFile, saveReferral } from 'pages/steps/actions-reducers/step.actions';
import { SAVE_FILE, SAVE_REFERRAL, SAVE_USER } from 'pages/steps/actions-reducers/step.types';
import { API_STATUS } from 'globals/api-constants';
import Step3Presentational from './step3-presentational';

const Step3Functional = () => {
	const [successModal, setSuccessModal] = useState(false);
	const saveUserRedux = useSelector(({ step }) => step?.saveUser?.userData);
	const physicianDetails = useSelector(({ common }) => common?.physicianDetails)?.data;
	const {
		roles: { data: roleData },
	} = useSelector(({ common }) => common);
	const {
		stepDetails: { step1, step2, step3 },
		resetValues,
	} = useStepContext();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const saveReferralRedux = useSelector(({ step }) => step?.saveReferral);
	const saveFileRedux = useSelector(({ step }) => step?.saveFile);
	const userRoleInText = useMemo(() => roleData.find((role) => role.utypeId === parseInt(step1?.userType))?.userType, [step1.userType, roleData]);

	const formatFiles = () => {
		let obj = {};
		step2?.files.forEach(({ file, fileName }, index) => {
			obj[`file_${index}`] = file;
			obj[`fileName_${index}`] = fileName;
			obj[`fileCategory_${index}`] = 1;
			// obj[`fileRecordId_${index}`] = 0;
			// obj[`isFile_${index}`] = 1;
		});
		return obj;
	};

	const handleFileSave = (referralId) => {
		const request = {
			userId: saveUserRedux[0].userId,
			patientId: 0,
			referralId,
			...formatFiles(),
			pfileLength: 0,
			awsRegion: 'USA',
			// allFileLength: (step2?.files || []).length,
		};
		let formData = new FormData();
		Object.keys(request).forEach((key) => {
			formData.append(key, request[key]);
		});
		dispatch(saveFile(formData));
	};

	const pageLoading = saveReferralRedux.apiStatus === API_STATUS.PENDING || saveFileRedux.apiStatus === API_STATUS.PENDING;

	useEffect(() => {
		if (checkStatus(saveReferralRedux.apiStatus)) {
			dispatch({
				type: SAVE_REFERRAL.RESET,
			});
			if (!!step2?.files.length) {
				handleFileSave(saveReferralRedux?.userData?.referralId);
			} else {
				setSuccessModal(true);
			}
		}
		if (checkStatus(saveFileRedux.apiStatus)) {
			dispatch({
				type: SAVE_FILE.RESET,
			});
			setSuccessModal(true);
		}
		// eslint-disable-next-line
	}, [saveReferralRedux, saveFileRedux, step2?.files.length, navigate, dispatch]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let referralParticipants = [];
		referralParticipants = saveUserRedux.map((userObj) => {
			return formReferralParticipants(userObj);
		});
		let request = {
			userId: saveUserRedux[0].userId || '',
			firstName: step2?.firstName || '',
			lastName: step2?.lastName || '',
			middleName: step2?.middleName || '',
			patientDob: step2?.dob,
			referralFor: step2?.reason || '',
			memberId: step2?.memberId || '',
			insurance: step2?.insurance || '',
			contactRelationName: step2?.contactName || '',
			contactRelationship: step2?.contactRelationship || '',
			contactRelationPhone: step2?.contactPhone || '',
	        isQuickReferral:1,
			mrn: '',
			patientPhoto: '',
			message: step2?.message,
			firstMessage: `Referred from ${step1?.firstName} ${step1?.lastName} at ${step1?.practiceName}`,
			referralParticipants,
			receivingParticipants: [
				{
					speciality_name: '',
					npi_number: physicianDetails?.npiNumber || '',
					city: physicianDetails?.physicalCity || '',
					last_name: physicianDetails?.lastName || '',
					photo: physicianDetails?.photo || '',
					middle_name: physicianDetails?.middleName || '',
					display_name: physicianDetails?.displayName || '',
					suffix: physicianDetails?.suffix || '',
					isNonVitalUser: 0,
					userId: physicianDetails?.userId || '',
					user_id: physicianDetails?.userId || '',
					state: physicianDetails?.physicalState || '',
					facilityName: '',
					department: physicianDetails?.department || '',
					first_name: physicianDetails?.firstName || '',
					$$hashKey: 'object:3470',
				},
			],
			otherParticipants: [],
			dicomImages: [],
			referralEvents: [],
			referralTodo: [],
			referralTags: [],
			patientId: 0,
			workflows: [],
			address1: step2?.address || '',
			country: '',
			state: step2?.state || '',
			city: step2?.city || '',
			email: '',
			phoneNumber: step2?.phone || '',
			postCode: '',
			patientIdentifier: '',
		};
		dispatch(saveReferral(request));
	};

	const handleCloseModal = () => {
		resetValues();
		dispatch({
			type: 'CLEAR_VERIFICATION_CODE',
		});
		dispatch({
			type: SAVE_USER.RESET,
		});
		setSuccessModal(false);
	};

	return (
		<Step3Presentational
			{...{
				navigate,
				step1,
				step2,
				step3,
				handleSubmit,
				userRoleInText,
				physicianDetails,
				successModal,
				setSuccessModal,
				handleCloseModal,
				pageLoading,
			}}
		/>
	);
};

export default Step3Functional;
