import Confetti from './confetti.json';
import HeartLoading from './heart-loading.json';
import Doctor from './doctor.json';

const LottieFile = {
	Confetti,
	HeartLoading,
	Doctor,
};

export default LottieFile;
