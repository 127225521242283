import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PhysicianDetailsProps, Step1ContextProps } from 'modal/common';
import Stepper from 'pages/steps/components/stepper';
import { extractOnlyCharactersWithSpace, extractOnlyNumbers, validateEmail } from 'helpers';
import { API_BASE_URL } from 'globals/api-url';
import PhoneNumberInput from 'components/PhoneNumberInput';

export const UserType = [
	{
		name: 'Physician',
		value: 'physician',
	},
	{
		name: 'Staff Member',
		value: 'staff',
	},
	{
		name: 'Physican Extender',
		value: 'physician-extender',
	},
	{
		name: 'Already a Member of VitalEngine',
		value: 'already-member',
	},
];

interface Step1Props {
	step1: Step1ContextProps;
	handleInputChange: Function;
	handleSubmit: () => void;
	roles: Array<{
		isNpiNumber: number;
		userType: string;
		utypeId: string;
	}>;
	specialities: Array<{
		specialityName: string;
		specialityId: string;
	}>;
	physicianDetails: PhysicianDetailsProps;
	referralRedux: any;
	filterRoles: any;
}

const Step1Presentational = ({
	step1,
	handleInputChange,
	handleSubmit,
	roles,
	specialities,
	physicianDetails,
	referralRedux,
	filterRoles,
}: Step1Props) => {
	// const isOptional = step1?.role && ((step1?.role || '').toLowerCase() === 'physician' || (step1?.role || '').toLowerCase() === 'already-member');
	const isOptional = (step1?.userType ?? '').toString() === '1';
	const alreadyAMember = (referralRedux?.referralData?.userVerificationDetail?.userType ?? '').toString() !== '0';
	const yourEmailValidation = !validateEmail(step1?.primaryEmail || '');
	const referringProviderEmailValidation = !validateEmail(step1?.referringProviderEmail || '');
	const requiredSymbol = alreadyAMember ? '' : '*';
	return (
		<form className="container pb-3 step1" onSubmit={handleSubmit}>
			<Stepper />
			<div className="row mt-3">
				<div className="col-sm-6">
					<div className="border box_shadow1 no_border border_radius h-100">
						<div className="bg-primary p-2 border_radius">
							<p className="m-0 text-white">From</p>
						</div>
						<div className="container-fluid py-2">
							<div className="row">
								<div className="col-sm-12 mb-3 col-md-6">
									<label className="form-label"> I am a :</label>
									<div className="d-flex flex-wrap">
										{referralRedux?.referralData?.userVerificationDetail?.userType !== 0 ? (
											<div className="form-check me-3">
												<input
													checked={true}
													className="form-check-input"
													type="radio"
													name="flexRadioDefault"
													title="Default"
												/>
												<label className="form-check-label">Already a Member of VitalEngine</label>
											</div>
										) : (
											<select
												title="Your Role"
												className="form-control"
												name="role"
												id="role"
												style={{ marginRight: 10 }}
												value={(step1?.userType || '0').toString()}
												required
												onChange={({ target: { value } }) => {
													handleInputChange('userType', value);
												}}>
												<option value="">Select Role</option>
												{roles?.map((role, index) => (
													<option key={index} value={role?.utypeId}>
														{role?.userType}
													</option>
												))}
											</select>
										)}
										{/* {UserType.map(({ name, value }, index) => (
											<div className="form-check me-3" key={index}>
												<input
													checked={value === step1?.userType}
													className="form-check-input"
													type="radio"
													name="flexRadioDefault"
													id={`flexRadioDefault${index}`}
													value={value}
													onChange={() => handleInputChange('userType', value)}
												/>
												<label className="form-check-label" htmlFor={`flexRadioDefault${index}`}>
													{name.toString()}
												</label>
											</div>
										))} */}
									</div>
								</div>
								{/* <form className="row"> */}

								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label"> Speciality*</label>
										<select
											title="Please select a speciality"
											className="form-control"
											name="speciality"
											id="speciality"
											disabled={referralRedux?.referralData?.userVerificationDetail?.userType !== 0}
											required={step1?.specialityUserId === ''}
											value={step1?.specialityUserId || ''}
											onChange={({ target: { value } }) => handleInputChange('specialityUserId', value)}>
											<option value="">Select Speciality</option>
											{specialities?.map((speciality, index) => (
												<option key={index} value={speciality?.specialityId}>
													{speciality?.specialityName}
												</option>
											))}
										</select>
									</div>
								</div>

								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Your First Name*</label>
										<input title="Your Name" required className="form-control" type="text" disabled value={step1?.firstName} />
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Your Last Name*</label>
										<input
											title="Your Last Name"
											required
											className="form-control"
											type="text"
											disabled
											value={step1?.lastName}
										/>
									</div>
								</div>

								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label
											{...{
												className: `form-label ${yourEmailValidation ? 'wrong' : ''}`,
											}}>
											Your Email* {`${yourEmailValidation ? '- Email is Incorrect' : ''}`}
										</label>
										<input
											title="Please enter a valid email address"
											className="form-control"
											type="text"
											required
											disabled
											value={step1?.primaryEmail}
											onChange={({ target: { value } }) => handleInputChange('primaryEmail', value)}
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Your Cell Phone*</label>
										<PhoneNumberInput
											onChange={(val) => handleInputChange('primaryMobile', val)}
											placeholder="Phone number"
											defaultValue={step1?.primaryMobile}
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Job Description{requiredSymbol}</label>
										<input
											title="Your Role"
											className="form-control"
											type="text"
											required={!alreadyAMember}
											disabled={alreadyAMember}
											value={step1?.department}
											onChange={({ target: { value } }) =>
												handleInputChange('department', extractOnlyCharactersWithSpace(value))
											}
										/>
										{/* <select
											title="Your Role"
											className="form-control"
											name="role"
											id="role"
											value={step1?.userType}
											required
											onChange={({ target: { value } }) => handleInputChange('userType', value)}>
											<option value="">Select Role</option>
											{roles?.map((role, index) => (
												<option key={index} value={role?.utypeId}>
													{role?.userType}
												</option>
											))}
										</select> */}
									</div>
								</div>
								{filterRoles && filterRoles.length && Number(filterRoles[0].isNpiNumber) === 1 ? (
									<div className="col-sm-12 col-md-6">
										<div className="mb-3">
											<label className="form-label">Your NPI Number{Number(filterRoles[0].isNpiNumber) === 1 ? '*' : ''}</label>
											<input
												title="Please enter a valid name"
												className="form-control"
												disabled={referralRedux?.referralData?.userVerificationDetail?.userType !== 0}
												// className={`form-control ${errors?.includes('referringProviderName') && 'error_shake'}`}
												type="text"
												required={Number(filterRoles[0].isNpiNumber) === 1}
												value={step1?.npiNumber}
												onChange={({ target: { value } }) => handleInputChange('npiNumber', extractOnlyNumbers(value))}
											/>
										</div>
									</div>
								) : (
									''
								)}
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Practice Name{requiredSymbol}</label>
										<input
											title="Please enter a valid practice name"
											className="form-control"
											type="text"
											required={!alreadyAMember}
											disabled={alreadyAMember}
											value={step1?.practiceName}
											onChange={({ target: { value } }) =>
												handleInputChange('practiceName', extractOnlyCharactersWithSpace(value))
											}
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Practice Phone{requiredSymbol}</label>
										<input
											title="Please enter a valid phone number"
											className="form-control"
											type="text"
											required={!alreadyAMember}
											disabled={alreadyAMember}
											value={step1?.practicePhone}
											onChange={({ target: { value } }) => handleInputChange('practicePhone', extractOnlyNumbers(value))}
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label className="form-label">Referring Provider Name{isOptional ? '' : '*'}</label>
										<input
											title="Please enter a valid name"
											className="form-control"
											// className={`form-control ${errors?.includes('referringProviderName') && 'error_shake'}`}
											type="text"
											required={!isOptional}
											value={step1?.referringProviderName}
											onChange={({ target: { value } }) =>
												handleInputChange('referringProviderName', extractOnlyCharactersWithSpace(value))
											}
										/>
									</div>
								</div>
								<div className="col-sm-12 col-md-6">
									<div className="mb-3">
										<label
											{...{
												className: `form-label ${referringProviderEmailValidation ? 'wrong' : ''}`,
											}}>
											Referring Provider Email{isOptional ? '' : '*'}{' '}
											{`${referringProviderEmailValidation ? '- Email is Incorrect' : ''}`}
										</label>
										<input
											title="Please enter a valid email address"
											className="form-control"
											type="text"
											required={!isOptional || !!step1?.referringProviderName}
											value={step1?.referringProviderEmail}
											onChange={({ target: { value } }) => handleInputChange('referringProviderEmail', value)}
										/>
									</div>
								</div>
								{step1?.referringProviderName !== '' ? (
									<div className="col-sm-12 col-md-6">
										<div className="mb-3">
											<label className="form-label">Referring Provider Speciality{isOptional ? '' : '*'}</label>
											<select
												disabled={!(step1?.referringProviderName || '')}
												title="Please select a speciality"
												className="form-control"
												name="speciality"
												id="speciality"
												value={step1?.specialityId || ''}
												required={!isOptional || !!step1?.referringProviderName}
												onChange={({ target: { value } }) => handleInputChange('specialityId', value)}>
												<option value="">Select Speciality</option>
												{specialities?.map((speciality, index) => (
													<option key={index} value={speciality?.specialityId}>
														{speciality?.specialityName}
													</option>
												))}
											</select>
										</div>
									</div>
								) : (
									''
								)}

								{/* </form> */}
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-1 align-self-center text-center my-3">
					<div className="mb-0 fa fa-chevron-right d-none d-sm-block"></div>
					<div className="mb-0 fa fa-chevron-down d-block d-sm-none"></div>
				</div>
				<div className="col-sm-5">
					<div className="border box_shadow1 no_border h-100 d-flex flex-column border_radius">
						<div className="bg-primary p-2 border_radius">
							<p className="m-0 text-white">To</p>
						</div>
						<div className="container-fluid py-2 flex-fill d-flex flex-column">
							<div className="row">
								<div className="col-sm-4">
									<img
										alt="img"
										className="w-100 box_shadow2 border_radius"
										src={
											physicianDetails?.photo
												? `${API_BASE_URL}/portal-api/${physicianDetails?.photo}`
												: 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'
										}
									/>
								</div>
								<div className="col-sm-8 align-self-center">
									<h6>{physicianDetails?.title}{physicianDetails?.title && physicianDetails?.title.length?' ':''}{physicianDetails?.displayName}{physicianDetails?.suffix && physicianDetails?.suffix.length?",":""}&nbsp;{physicianDetails?.suffix}</h6>
									<p className="mb-0 small">
										{physicianDetails?.department}
										<br /> {physicianDetails?.specialityName}
									</p>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12">
									<address>
										{physicianDetails?.physicalAddress1 || ''} <br />
										{`${physicianDetails?.physicalAddress2 || ''} ${physicianDetails?.physicalCity || ''}, ${
											physicianDetails?.physicalPostalCode || ''
										}`}{' '}
										<br />
										{physicianDetails?.primaryMobile || ''}
									</address>
								</div>
							</div>
							<div className="row mt-auto">
								<div className="col-12 d-flex justify-content-end">
									<p className="mb-0 align-self-center me-2">Next</p>
									<button title="next" type="submit" className="btn btn-outline-dark btn-sm">
										<FontAwesomeIcon icon={solid('chevron-right')} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default Step1Presentational;
