import React from 'react';
import { Outlet } from 'react-router-dom';
import VHeader from './VHeader';

const PageLayout = () => {
	return (
		<>
			<VHeader />
			<Outlet />
		</>
	);
};

export default PageLayout;
