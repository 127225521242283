import { API_STATUS } from 'globals/api-constants';
import { SAVE_FILE, SAVE_REFERRAL, SAVE_USER } from './step.types';

const INITIAL_STATE = {
	saveUser: {
		apiStatus: '',
		userData: null,
	},
	saveReferral: {
		apiStatus: '',
		userData: null,
	},
	saveFile: {
		apiStatus: '',
	},
};
const stepReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case SAVE_USER.REQUEST:
			return {
				...state,
				saveUser: {
					...state.saveUser,
					apiStatus: API_STATUS.PENDING,
					// userData: null,
				},
			};
		case SAVE_USER.SUCCESS:
			return {
				...state,
				saveUser: { ...state.saveUser, apiStatus: API_STATUS.SUCCESS, userData: action?.details },
			};
		case SAVE_USER.ERROR:
			return {
				...state,
				saveUser: { ...state.saveUser, apiStatus: API_STATUS.ERROR, userData: null },
			};
		case SAVE_USER.RESET:
			return {
				...state,
				saveUser: {
					...state.saveUser,
					apiStatus: '',
					// userData: null,
				},
			};
		case SAVE_REFERRAL.REQUEST:
			return {
				...state,
				saveReferral: {
					...state.saveReferral,
					apiStatus: API_STATUS.PENDING,
				},
			};
		case SAVE_REFERRAL.SUCCESS:
			return {
				...state,
				saveReferral: {
					...state.saveReferral,
					userData: action?.details,
					apiStatus: API_STATUS.SUCCESS,
				},
			};
		case SAVE_REFERRAL.ERROR:
			return {
				...state,
				saveReferral: {
					...state.saveReferral,
					apiStatus: API_STATUS.ERROR,
				},
			};
		case SAVE_REFERRAL.RESET:
			return {
				...state,
				saveReferral: {
					...state.saveReferral,
					apiStatus: '',
				},
			};
		case SAVE_FILE.REQUEST:
			return {
				...state,
				saveFile: {
					...state.saveFile,
					apiStatus: API_STATUS.PENDING,
				},
			};
		case SAVE_FILE.SUCCESS:
			return {
				...state,
				saveFile: {
					...state.saveFile,
					apiStatus: API_STATUS.SUCCESS,
				},
			};
		case SAVE_FILE.ERROR:
			return {
				...state,
				saveFile: {
					...state.saveFile,
					apiStatus: API_STATUS.ERROR,
				},
			};
		case SAVE_FILE.RESET:
			return {
				...state,
				saveFile: {
					...state.saveFile,
					apiStatus: '',
				},
			};

		default:
			return state;
	}
};
export default stepReducer;
