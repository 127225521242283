import { combineReducers } from 'redux';
import commonReducer from './common/common.reducer';
import referralCodeReducer from 'pages/referral-code/actions-reducers/referral-code.reducer';
import stepReducer from 'pages/steps/actions-reducers/step.reducer';

const rootReducer = combineReducers({
	common: commonReducer,
	referral: referralCodeReducer,
	step: stepReducer,
});
export default rootReducer;
