import React from 'react';
import { Col, Row } from 'antd';
import { LottieComponent } from 'components';

const LoadingScreenPresentational = ({ transparent = false }) => {
	return (
		<Row justify="center" align="middle" className="loading_screen" style={{ ...(transparent ? { background: '#ffffff87' } : {}) }}>
			<Col>
				<LottieComponent width={'10%'} />
			</Col>
		</Row>
	);
};

export default LoadingScreenPresentational;
