import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { getIcons } from 'helpers';
import './style.scss';

interface FileProps {
	name?: string;
	files: Array<{ name: string; type: string }>;
	handleRemove: (e: any) => void;
}

const FileList = ({ files = [], handleRemove }: FileProps) => {
	return (
		<div className="file_list">
			<p className="fw-bold">Attachments</p>
			<div className="row">
				{files?.map(({ name, type }, index) => (
					<div key={index} className="col-sm-4 col-md-3">
						<div className="d-flex flex-column my-2" style={{ position: 'relative' }}>
							<div className="remove" onClick={() => handleRemove(name)}>
								<FontAwesomeIcon icon={faClose} />
							</div>
							<FontAwesomeIcon className="h1" icon={getIcons(type)} />
							<small className="text-ellipsis">{name}</small>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FileList;
