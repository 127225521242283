// import moment from 'moment';
import React, { useState, useContext } from 'react';

const StepContext = React.createContext();

const DEFAULT_VALUES = {
	step1: {
		department: '',
		firstName: '',
		lastName: '',
		userType: 0,
		primaryEmail: '',
		primaryMobile: '',
		practiceName: '',
		practicePhone: '',
		referringProviderName: '',
		referringProviderEmail: null,
		specialityId: '',
		npiNumber: '',
		npiRefNumber: '',
		specialityUserId: '',
	},
	step2: {
		firstName: '',
		lastName: '',
		middleName: '',
		phone: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		dob: null,
		instance: '',
		memberId: '',
		contactName: '',
		contactRelationship: '',
		contactPhone: '',
		reason: '',
		message: '',
		files: [],
	},
	step3: {},
};

const StepProvider = ({ children }) => {
	const [stepDetails, setStepDetails] = useState(DEFAULT_VALUES);

	const resetValues = () => {
		setStepDetails(DEFAULT_VALUES);
	};

	const handleStep1Values = (values) => {
		setStepDetails({
			...stepDetails,
			step1: {
				...stepDetails.step1,
				...values,
			},
		});
	};

	const handleStepInputChange = (step, label, value) => {
		setStepDetails({
			...stepDetails,
			[step]: {
				...stepDetails[step],
				[label]: value,
			},
		});
	};

	return (
		<StepContext.Provider
			value={{
				stepDetails,
				handleStepInputChange,
				resetValues,
				handleStep1Values,
			}}>
			{children}
		</StepContext.Provider>
	);
};

const useStepContext = () => {
	return useContext(StepContext);
};

export { StepProvider, useStepContext };
