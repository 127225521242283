import { API_STATUS } from 'globals/api-constants';
import { faFile, faImage, faFilePdf, faFileVideo, faFileWord } from '@fortawesome/free-solid-svg-icons';

export const createAction = (module: any) => ({
	REQUEST: `${module}_REQUEST`,
	LOADING: `${module}_LOADING`,
	SUCCESS: `${module}_SUCCESS`,
	ERROR: `${module}_ERROR`,
	RESET: `${module}_RESET`,
});

export const checkStatus = (value = '', statusValue = API_STATUS.SUCCESS) => value === statusValue;

export const handleSplitName = (name = '') => {
	const splittedName = name.split(' ');
	let fields = {
		firstName: '',
		lastName: '',
		middleName: '',
	};
	if (splittedName.length === 1) {
		fields = { ...fields, firstName: splittedName[0] };
	}
	if (splittedName.length === 2) {
		fields = { ...fields, firstName: splittedName[0], lastName: splittedName[1] };
	}
	if (splittedName.length === 3) {
		fields = { ...fields, firstName: splittedName[0], middleName: splittedName[1], lastName: splittedName[2] };
	}
	// fields = Object.fromEntries(Object.entries(fields).filter(([_, v]) => v !== ''));
	return fields;
};

export const removeEmptyFields = (obj = {}) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''));

export const getLengthOfObj = (obj = {}) => Object.keys(obj).length;

export const checkTheObjectAllFilledWithValues = (obj = {}) => {
	const withValues = Object.fromEntries(Object.entries(obj).filter(([, value]) => value));
	const hasValues = getLengthOfObj(withValues) === getLengthOfObj(obj);
	return hasValues;
};

const FileIcons = [
	{
		name: 'image/png',
		icon: faImage,
	},
	{
		name: 'image/jpeg',
		icon: faImage,
	},
	{
		name: 'application/msword',
		icon: faFileWord,
	},
	{
		name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		icon: faFileWord,
	},
	{
		name: 'application/pdf',
		icon: faFilePdf,
	},
	{
		name: 'video/mp4',
		icon: faFileVideo,
	},
];

export const getIcons = (type = '') => {
	const icon = FileIcons.find((file) => file.name === type);
	return icon?.icon || faFile;
};

export const formReferralParticipants = (obj: any) => {
	let requestObj = {
		userId: obj?.userId || '',
		fullName: `${obj?.firstName}${obj?.lastName ? ` ${obj?.lastName}` : ''}${obj?.middleName ? ` ${obj?.middleName}` : ''}`,
		photo: obj?.photo || '',
		practiceName: obj?.practiceName || '',
		practiceId: obj?.practiceId || '',
		suffix: obj?.suffix || '',
		department: obj?.department || '',
		practicePhone: obj?.practicePhone || '',
	};
	return requestObj;
};

export const extractOnlyCharacters = (value = '') => value.replace(/[^A-Za-z]/gi, '');
export const extractOnlyCharactersWithSpace = (value = '') => value.replace(/[^A-Za-z ]/gi, '');
export const extractOnlyNumbers = (value = '') => value.replace(/[^0-9]/gi, '');
export const validateEmail = (email = '') => {
	let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (email.length < 1) return true;
	return !!(email.length >= 1 && reg.test(email));
};
