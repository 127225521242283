import { GET_REFERRAL_CODE, VERIFY_REFERRAL_CODE } from './referral-code.types';

const INITIAL_STATE = {
	apiStatus: '',
	referralData: null,
	codeVerified: false,
};
const referralCodeReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case GET_REFERRAL_CODE.REQUEST:
			return {
				...state,
				apiStatus: 'PENDING',
			};
		case GET_REFERRAL_CODE.SUCCESS:
			return {
				...state,
				apiStatus: 'SUCCESS',
				referralData: action?.details,
			};
		case GET_REFERRAL_CODE.ERROR:
			return {
				...state,
				apiStatus: 'ERROR',
				referralData: null,
			};
		case GET_REFERRAL_CODE.RESET:
			return {
				...state,
				apiStatus: '',
				// referralData: null,
			};
		case VERIFY_REFERRAL_CODE.REQUEST:
			return {
				...state,
				apiStatus: 'PENDING',
			};
		case VERIFY_REFERRAL_CODE.SUCCESS:
			return {
				...state,
				apiStatus: 'SUCCESS',
				referralData: action?.details,
				codeVerified: true,
			};
		case VERIFY_REFERRAL_CODE.ERROR:
			return {
				...state,
				apiStatus: 'ERROR',
				codeVerified: false,
				codeVerification: { tokenExpired: true },
			};
		case VERIFY_REFERRAL_CODE.RESET:
			return {
				...state,
				apiStatus: '',
			};
		case 'CLEAR_VERIFICATION_CODE':
			return {
				...state,
				codeVerified: false,
			};
		default:
			return state;
	}
};
export default referralCodeReducer;
