import React, { useEffect, useRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export interface PhoneNumberInputProps {
  className?: string;
  defaultValue?: string;
  onChange(value: string): void;
  defaultCountry?: string;
  placeholder?: string;
  disabled?: boolean;
}
export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const {
    className,
    defaultValue,
    onChange,
    defaultCountry,
    placeholder,
    disabled,
  } = props;
  const phoneInputRef = useRef<any>(null);

  useEffect(() => {
    if (!phoneInputRef?.current) {
      return;
    }
    phoneInputRef.current.type = 'tel';
    phoneInputRef.current.classList.add("form-control");
    if (className) {
      phoneInputRef.current.classList.add(className);
    }
  }, [phoneInputRef, className]);

  return (
    <PhoneInput
      // @ts-ignore
      defaultCountry={defaultCountry || "US"}
      value={defaultValue}
      smartCaret
      disabled={disabled}
      ref={phoneInputRef}
      placeholder={placeholder}
      limitMaxLength
      onChange={(val) => onChange(val as string)}
    />
  );
}
