import axios from 'axios';
import { message } from 'antd';
import { API_URL } from 'globals/api-url';
import { APP_VARIABLES } from 'constants/app-constants';
import { GET_REFERRAL_CODE, VERIFY_REFERRAL_CODE } from './referral-code.types';

export const getReferralCode =
	(data = {}) =>
	async (dispatch: any) => {
		await dispatch({
			type: GET_REFERRAL_CODE.REQUEST,
		});
		try {
			delete axios.defaults.headers.common['Authorization'];
			const { data: details } = await axios.post(API_URL.GET_REFERRAL_CODE, data);
			localStorage.setItem(APP_VARIABLES.ACCESS_TOKEN, details.response.access_token);
			await dispatch({
				type: GET_REFERRAL_CODE.SUCCESS,
				details,
			});
		} catch (error) {
			await dispatch({
				type: GET_REFERRAL_CODE.ERROR,
			});
		}
	};

export const verifyReferralCode =
	(data = {}) =>
	async (dispatch: any) => {
		await dispatch({
			type: VERIFY_REFERRAL_CODE.REQUEST,
		});
		try {
			const { data: details } = await axios.post(API_URL.VERIFY_REFERRAL_CODE, data);
			if (details?.response_code === '0') {
				await dispatch({
					type: VERIFY_REFERRAL_CODE.SUCCESS,
					details,
				});
			} else {
				message.error(details?.response_message || 'Something went wrong');
				await dispatch({
					type: VERIFY_REFERRAL_CODE.ERROR,
				});
			}
		} catch (error) {
			await dispatch({
				type: VERIFY_REFERRAL_CODE.ERROR,
			});
		}
	};
