import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { checkStatus, handleSplitName } from 'helpers';
import { useStepContext } from 'context/step-context';
import { saveUser } from 'pages/steps/actions-reducers/step.actions';
import { SAVE_USER } from 'pages/steps/actions-reducers/step.types';
import { notification } from 'utils/notification';
import Step1Presentational from './step1-presentational';

const formatRequest = (obj, roles) => {
	return {
		firstName: obj?.firstName,
		lastName: obj?.lastName,
		userType: obj?.userType,
		department: obj?.department,
		userName: obj?.primaryEmail,
		primaryEmail: obj?.primaryEmail,
		primaryMobile: obj?.primaryMobile,
		practiceName: obj?.practiceName,
		practicePhone: obj?.practicePhone,
		npiNumber: obj?.npiNumber,
		npiRefNumber: obj?.npiRefNumber,
		specialityUserId: obj?.specialityUserId,
	};
};

const Step1Functional = () => {
	const {
		roles: { data: roles },
		specialities: { data: specialities },
	} = useSelector(({ common }) => common);
	const {
		stepDetails: { step1 },
		handleStepInputChange,
	} = useStepContext();
	const [backupData, setBackupData] = useState(formatRequest(step1, roles));
	const referralRedux = useSelector(({ referral }) => referral);
	const saveUserRedux = useSelector(({ step: { saveUser } }) => saveUser);
	const physicianDetails = useSelector(({ common }) => common?.physicianDetails)?.data;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleDispatchBackupData = useCallback(() => {
		setBackupData(formatRequest(step1, roles));
	}, [step1, roles]);

	useEffect(() => {
		if (checkStatus(saveUserRedux.apiStatus)) {
			notification({
				message: 'User Saved Successfully!',
			});
			handleDispatchBackupData();
			navigate('/step2');
			dispatch({
				type: SAVE_USER.RESET,
			});
		}
	}, [saveUserRedux, navigate, dispatch, handleDispatchBackupData]);

	const handleInputChange = (label, value) => {
		handleStepInputChange('step1', label, value);
	};

	const filterRoles = roles?.filter((values) => Number(values.utypeId) === Number(step1?.userType));

	const handleSubmit = (e) => {
		e.preventDefault();
		let request = [formatRequest(step1, roles)];
		if (step1?.referringProviderName && step1?.referringProviderEmail && step1?.referringProviderEmail !== step1?.primaryEmail) {
			request.push({
				...handleSplitName(step1?.referringProviderName),
				specialityId: step1?.referringProviderName ? step1?.specialityId : '',
				primaryEmail: step1?.referringProviderEmail,
				userName: step1?.referringProviderEmail,
				npiNumber: Number(step1?.npiRefNumber),
			});
		}
		request[0].specialityId = step1?.specialityUserId;
		if (filterRoles && filterRoles.length && Number(filterRoles[0].isNpiNumber) !== 1) {
			request[0].npiNumber = '';
		}
		delete request[0].npiRefNumber;
		delete request[0].specialityUserId;
		isEqual(backupData, request[0]) && saveUserRedux?.userData ? navigate('/step2') : dispatch(saveUser(request));
	};

	const handleGetCode = () => {
		navigate('/step2');
	};
	return (
		<Step1Presentational
			{...{ handleGetCode, step1, handleInputChange, handleSubmit, roles, specialities, physicianDetails, referralRedux, filterRoles }}
		/>
	);
};

export default Step1Functional;
