import React from 'react';
import { Images } from 'assets/images';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const STEPS = [
	{
		title: 'Step 1',
		text: 'Who are you?',
		route: '/step1',
	},
	{
		title: 'Step 2',
		text: 'Who is your patient?',
		route: '/step2',
	},
	{
		title: 'Step 3',
		text: 'Finalize and Send',
		route: '/step3',
	},
];

const Stepper = () => {
	const location = useLocation();
	// const navigate = useNavigate();

	const activeStep = useMemo(() => location?.pathname, [location]);
	return (
		<>
			<div className="row py-4">
				<div className="col-sm-8">
					<div className="d-flex flex-nowrap">
						{STEPS.map(({ title, text, route }, index) => (
							<div
								key={index}
								// onClick={() => navigate(route)}
								className={`step rounded p-2 me-3 ${route === activeStep ? 'border' : ''}`}>
								<h4>{title}</h4>
								<p className="mb-0">{text}</p>
							</div>
						))}
					</div>
				</div>
				<div className="col-sm-4 d-flex justify-content-end">
					<div>
						<h2>New Referral</h2>
						<p className="mb-0">Powered by VitalEngine</p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<hr />
				</div>
			</div>
			<div className="row justify-content-end" style={{ marginTop: '-32px', position: 'relative' }}>
				<div className="col-1">
					<img alt="img" src={Images.vLogo} className="w-50 bg-white px-1" />
				</div>
			</div>
		</>
	);
};

export default Stepper;
