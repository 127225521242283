import React from 'react';
import { Images } from 'assets/images';
import { VButton } from 'components';

interface VerifyCodePresentationalProps {
	verificationCode: string;
	isLoading: boolean;
	disabled: boolean;
	handleVerifyCode: () => void;
	setVerificationCode: (verificationCode: string) => void;
	verificationResetCode: () => void;
	verifyCode: boolean;
}

const VerifyCodePresentational = ({
	handleVerifyCode,
	verificationCode = '',
	setVerificationCode,
	isLoading,
	disabled,
	verificationResetCode,
	verifyCode,
}: VerifyCodePresentationalProps) => {
	return (
		<div className="container vertical-align">
			<div className="row">
				<div className="col-sm-12 align-self-center my-sm-0 text-center mb-3">
					<img className="logo my-3" src={Images.vLogo} alt="#"></img>
				</div>
				<div className="col-sm-12 align-self-center  text-center">
					<h5>Welcome to VitalEngine Quick Refer</h5>
					<p className="mb-0 small">Please Enter Your Quick Code from</p>
					<p className="mb-0 small">your email or text to get started</p>
				</div>
				<div className="col-sm-12 align-self-center  text-center">
					<div className="row my-3 justify-content-center">
						<div className="col-sm-6 col-xl-3">
							<input
								title="Verification Code"
								maxLength={6}
								value={verificationCode}
								onChange={({ target: { value } }) => setVerificationCode(value)}
								type="text"
								className="form-control w-100"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row my-3 justify-content-center">
				<div className="col-sm-6 col-xl-3">
					<VButton loading={isLoading} disabled={disabled} onClick={handleVerifyCode}>
						Verify Code
					</VButton>
				</div>
			</div>
			<div className="row my-3 justify-content-center">
				<div className="col-sm-6 col-xl-3">
					<VButton loading={isLoading} disabled={!verifyCode || isLoading} onClick={verificationResetCode}>
						Resend Code
					</VButton>
				</div>
			</div>
		</div>
	);
};

export default VerifyCodePresentational;
