import React from 'react';
import { Col, Row } from 'antd';
import { LottieComponent } from 'components';
import LottieFile from 'assets/lottie-files';

const NoAccess = () => {
	return (
		<Row justify="center" align="middle" className="loading_screen">
			<Col className="error">Please check the URL</Col>
			<Col>
				<LottieComponent width={'60%'} file={LottieFile.Doctor} />
			</Col>
		</Row>
	);
};

export default NoAccess;
