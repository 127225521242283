import React from 'react';
import Lottie from 'react-lottie';
import LottieFile from 'assets/lottie-files';

const defaultOptions = {
	loop: true,
	autoplay: true,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

interface LottieComponentProps {
	file?: Object;
	width?: string;
	height?: string;
	loop?: boolean;
}

const LottieComponent = ({ width = '100%', height = '100%', loop = true, file = LottieFile.HeartLoading }: LottieComponentProps) => {
	return <Lottie isClickToPauseDisabled={true} options={{ ...defaultOptions, animationData: file, loop }} width={width} height={height} />;
};

export default LottieComponent;
