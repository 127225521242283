module.exports = {
	API_BASE_URL: process.env.REACT_APP_VITALPACS_API_BASE_URI,
	LOGOUT: '/logout',

	API_URL: {
		// common
		GET_SPECIALITIES: '/portal-api/rest/speciality',
		GET_ROLES: '/portal-api/rest/userType ',
		GET_PHYSICIAN_DETAILS: '/portal-api/api/get/provider',

		// referral code APIs
		GET_REFERRAL_CODE: '/portal-api/api/user-verification-code/save',
		VERIFY_REFERRAL_CODE: '/portal-api/api/user-verification-code/verify',

		// step APIs
		SAVE_USER: '/portal-api/api/quick-referral/user/save',
		SAVE_REFERRAL: '/portal-api/api/quick-referral/save',
		SAVE_FILE: '/dicom-cstore/uploadNewReferralRecords',
	},
};
