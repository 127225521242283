import Axios from 'axios';
import { message } from 'antd';
import { APP_VARIABLES } from 'constants/app-constants';
import ApiError from './api-error';
import ERRORS from './errorConstants';
import { API_BASE_URL } from './api-url';

const setupInterceptors = (store) => {
	Axios.defaults.baseURL = API_BASE_URL;
	Axios.defaults.headers.post['Content-Type'] = 'application/json';
	Axios.defaults.validateStatus = () => true;
	Axios.interceptors.request.use(
		(config) => {
			if (config?.url.includes('/portal-api/api/get/provider?')) {
				config.headers['user-tz'] = new Date().getTimezoneOffset();
				return config;
			} else {
				const token = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN);
				config.headers['user-tz'] = new Date().getTimezoneOffset();
				if (token) {
					// const access_token = token && JSON.parse(token).access_token;
					const access_token = token;
					config.headers.Authorization = `Bearer ${access_token}`;
				}
				return config;
			}
		},
		(error) => Promise.reject(error)
	);
	Axios.interceptors.response.use(
		(response) => {
			// Process response body
			if (response.status && parseInt(response.status, 0) > 205) {
				const resMessage = response?.data?.response_message || 'Something went wrong';
				switch (response.status) {
					case 500:
						message.error('Something went wrong');
						throw new ApiError(ERRORS.SERVER_ERROR);
					case 401:
						message.error('Expired');
						localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response.data.error.message);
					case 403:
						localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
						message.error(resMessage);
						throw new ApiError(
							ERRORS.FORBIDDEN_ERROR,
							response && response.data && response.data.Message ? response.data.Message : 'Network Error'
						);
					case 406:
						message.error(resMessage);
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response.data.error.message);
					case 400:
						window.location.replace('/');
						message.error(resMessage);
						throw new ApiError(ERRORS.UNAUTHORISED_ERROR, response?.data?.response_message);
					case 404:
						message.error(resMessage);
						throw new ApiError(ERRORS.CLIENT_ERROR);
					default:
						message.error(resMessage);
						throw new ApiError(ERRORS.CLIENT_ERROR);
				}
			} else {
				return response;
			}
		},
		(error) => {
			message.error(ERRORS.SOMETHING_WENT_WRONG.message);
			Promise.reject(error);
		}
	);
};

/**
 * Set auth token as default in Axios
 * @param token
 */
export const setAuthToken = (token = localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)) => {
	Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)}`;
};
export default setupInterceptors;
