import { API_STATUS } from 'globals/api-constants';
import { INCREMENT, DECREMENT, GET_ROLES, GET_SPECIALITIES, GET_PHYSICIAN_DETAILS } from './common.types';

const INITIAL_STATE = {
	count: 0,
	users: [],
	roles: {
		data: [],
		apiStatus: '',
	},
	specialities: {
		data: [],
		apiStatus: '',
	},
	physicianDetails: {
		data: {},
		apiStatus: '',
	},
};
const commonReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case INCREMENT:
			return {
				...state,
				count: state.count + 1,
			};
		case DECREMENT:
			return {
				...state,
				count: state.count - 1,
			};
		case GET_ROLES.REQUEST:
			return {
				...state,
				roles: {
					...state.roles,
					apiStatus: API_STATUS.PENDING,
				},
			};
		case GET_ROLES.SUCCESS:
			return {
				...state,
				roles: {
					...state.roles,
					apiStatus: API_STATUS.SUCCESS,
					data: action?.details,
				},
			};
		case GET_ROLES.ERROR:
			return {
				...state,
				roles: {
					...state.roles,
					apiStatus: API_STATUS.ERROR,
				},
			};
		case GET_ROLES.RESET:
			return {
				...state,
				roles: {
					roles: [],
					apiStatus: '',
				},
			};
		case GET_SPECIALITIES.REQUEST:
			return {
				...state,
				specialities: {
					...state.specialities,
					apiStatus: API_STATUS.PENDING,
				},
			};
		case GET_SPECIALITIES.SUCCESS:
			return {
				...state,
				specialities: {
					...state.specialities,
					apiStatus: API_STATUS.SUCCESS,
					data: action.details,
				},
			};
		case GET_SPECIALITIES.ERROR:
			return {
				...state,
				specialities: {
					...state.specialities,
					apiStatus: API_STATUS.ERROR,
					specialities: [],
				},
			};
		case GET_SPECIALITIES.RESET:
			return {
				...state,
				specialities: {
					specialities: [],
					apiStatus: '',
				},
			};
		case GET_PHYSICIAN_DETAILS.REQUEST:
			return {
				...state,
				physicianDetails: {
					...state.physicianDetails,
					apiStatus: API_STATUS.PENDING,
				},
			};
		case GET_PHYSICIAN_DETAILS.SUCCESS:
			return {
				...state,
				physicianDetails: {
					...state.physicianDetails,
					apiStatus: API_STATUS.SUCCESS,
					data: action.details,
				},
			};
		case GET_PHYSICIAN_DETAILS.ERROR:
			return {
				...state,
				physicianDetails: {
					...state.physicianDetails,
					apiStatus: API_STATUS.ERROR,
					data: {},
				},
			};
		case GET_PHYSICIAN_DETAILS.RESET:
			return {
				...state,
				physicianDetails: {
					apiStatus: '',
					data: {},
				},
			};

		default:
			return state;
	}
};
export default commonReducer;
