import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Step2ContextProps } from 'modal/common';
import FileList from 'pages/steps/components/file-list';
import Stepper from 'pages/steps/components/stepper';
import { extractOnlyCharacters, extractOnlyCharactersWithSpace, extractOnlyNumbers } from 'helpers';
import { DatePicker } from 'antd';
import PhoneNumberInput from 'components/PhoneNumberInput';

const MAX_LENGTH = 50;

interface Step2Props {
	navigate: (a: Number) => void;
	handleFiles: () => void;
	handleInputChange: (label: string, value: string) => void;
	handleSubmit: () => void;
	step2: Step2ContextProps;
	inputFile: any;
	removeFile: (file: any) => void;
}

const Step2Presentational = ({ inputFile, handleFiles, handleSubmit, navigate, step2, handleInputChange, removeFile }: Step2Props) => {
	return (
		<form className="container step2 pb-3" onSubmit={handleSubmit}>
			<Stepper />
			<div className="container-fluid">
				<div className="row border box_shadow1 no_border mt-3 border_radius">
					<div className="col-sm-8 px-0">
						<div className="bg-primary p-2 text-white border_radius">
							<p className="mb-0">Patient Demographics</p>
						</div>
						<div className="row p-3">
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">First Name*</label>
									<input
										title="First Name"
										className="form-control"
										required
										value={step2?.firstName}
										onChange={({ target: { value } }) => handleInputChange('firstName', extractOnlyCharacters(value))}
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">Middle Name</label>
									<input
										title="Middle Name"
										className="form-control"
										value={step2?.middleName}
										onChange={({ target: { value } }) => handleInputChange('middleName', extractOnlyCharacters(value))}
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">Last Name*</label>
									<input
										title="Last Name"
										className="form-control"
										required
										value={step2?.lastName}
										onChange={({ target: { value } }) => handleInputChange('lastName', extractOnlyCharacters(value))}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">Address</label>
									<input
										title="Address"
										className="form-control"
										value={step2?.address}
										onChange={({ target: { value } }) => handleInputChange('address', value)}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">City</label>
									<input
										title="City"
										className="form-control"
										value={step2?.city}
										onChange={({ target: { value } }) => handleInputChange('city', extractOnlyCharacters(value))}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">State</label>
									<input
										title="State"
										className="form-control"
										value={step2?.state}
										onChange={({ target: { value } }) => handleInputChange('state', extractOnlyCharacters(value))}
									/>
								</div>
							</div>
							{/* <div class="spinner-border" role="status">
								<span class="sr-only"></span>
							</div> */}
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">ZIP</label>
									<input
										title="zip"
										className="form-control"
										value={step2?.zip}
										onChange={({ target: { value } }) => handleInputChange('zip', value)}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">Date of Birth*(MM/DD/YYYY)</label>
									<input
										style={{ height: 0.1, padding: 0, opacity: 0 }}
										title="Date fo Birth"
										type="date"
										required={!!!step2?.dob}
										className="form-control"
										value={step2?.dob}
										placeholder="MM/DD/YYYY"
										max={moment().format('YYYY-MM-DD')}
										onChange={({ target: { value } }) => handleInputChange('dob', value)}
									/>
									<DatePicker
										allowClear={false}
										style={{ width: '100%', padding: '.375rem .75rem' }}
										onChange={(value) => {
											handleInputChange('dob', moment(value).format('MM/DD/YYYY'));
										}}
										{...{
											format: 'MM/DD/YYYY',
											...(step2?.dob && { value: moment(step2?.dob, 'MM/DD/YYYY') }),
										}}
										disabledDate={(current) => {
											let customDate = moment().format('MM/DD/YYYY');
											return current && current > moment(customDate, 'MM/DD/YYYY');
										}}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">Phone Number*</label>
									<PhoneNumberInput
										placeholder="Phone Number"
										defaultValue={step2?.phone}
										onChange={(value) => handleInputChange('phone', value)}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">Insurance</label>
									<input
										title="Insurance"
										className="form-control"
										value={step2?.insurance}
										onChange={({ target: { value } }) => handleInputChange('insurance', value)}
									/>
								</div>
							</div>
							<div className="col-sm-3 col-md-4">
								<div className="mb-3">
									<label className="form-label">Member ID</label>
									<input
										title="Member ID"
										className="form-control"
										value={step2?.memberId}
										onChange={({ target: { value } }) => handleInputChange('memberId', value)}
									/>
								</div>
							</div>
						</div>
						<div className="bg-primary p-2 text-white border_radius">
							<p className="mb-0">Other Contact Info</p>
						</div>
						<div className="row p-3">
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">Contact Name</label>
									<input
										title="Contact Name"
										className="form-control"
										value={step2?.contactName}
										onChange={({ target: { value } }) => handleInputChange('contactName', extractOnlyCharactersWithSpace(value))}
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">Contact Relationship</label>
									<input
										title="Contact Relationship"
										className="form-control"
										value={step2?.contactRelationship}
										onChange={({ target: { value } }) =>
											handleInputChange('contactRelationship', extractOnlyCharactersWithSpace(value))
										}
									/>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="mb-3">
									<label className="form-label">Contact Phone</label>
									<input
										title="Contact Phone"
										className="form-control"
										value={step2?.contactPhone}
										maxLength={15}
										onChange={({ target: { value } }) => handleInputChange('contactPhone', extractOnlyNumbers(value))}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-4 px-0 border-start  d-flex flex-column">
						<div className="bg-primary p-2 text-white">
							<p className="mb-0">Referral Details</p>
						</div>
						<div className="flex-fill d-flex flex-column p-3">
							<div className="mb-3">
								<label className="form-label">Reason for Referral</label>
								<input
									type="text"
									title="Brief Reason for Referral"
									className="form-control"
									maxLength={MAX_LENGTH}
									value={step2?.reason}
									onChange={({ target: { value } }) => handleInputChange('reason', value)}
								/>
								<div style={{ textAlign: 'right', paddingTop: 5 }}>
									({(step2?.reason || '').length}/{MAX_LENGTH})
								</div>
							</div>
							<div className="mb-3">
								<label className="form-label"> Additional Referral details*</label>
								<textarea
									title="Additional Referral details"
									className="form-control"
									required
									rows={4}
									maxLength={1500}
									value={step2?.message}
									onChange={({ target: { value } }) => handleInputChange('message', value)}
								/>
								<div style={{ textAlign: 'right', paddingTop: 5 }}>
									({(step2?.message || '').length}/{1500})
								</div>
							</div>
							<div className="mb-3">
								<label className="form-label">Add Any Attachments Here</label>
								<div className="border p-3 d-flex justify-content-center">
									<input
										title="Add Files"
										multiple
										type="file"
										id="file"
										ref={inputFile}
										onChange={handleFiles}
										style={{ display: 'none' }}
									/>
									<div className="btn btn-outline-dark" onClick={() => inputFile.current.click()}>
										Add File(s)
									</div>
								</div>
							</div>
							<FileList {...{ files: step2?.files, handleRemove: removeFile }} />
							<div className="mt-auto d-flex justify-content-between">
								<div className="d-flex">
									<button title="Add" onClick={() => navigate(-1)} className="btn btn-outline-dark btn-sm">
										<FontAwesomeIcon icon={solid('chevron-left')} />
									</button>
									<p className="mb-0 align-self-center ms-2">Back</p>
								</div>
								<div className="d-flex">
									<p className="mb-0 align-self-center me-2">Next</p>
									<button title="Next" type="submit" className="btn btn-outline-dark btn-sm">
										<FontAwesomeIcon icon={solid('chevron-right')} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default Step2Presentational;
